
        $(window).on('beforeunload', function() {
            $(window).scrollTop(0);
        });

        $(document).ready(function() {
            $('ul.nav.navbar-nav')
                .find('a[href="' + location.pathname + '"]')
                .closest('li').addClass('active');

            const canSee = $("#myNavbar").is(":visible");
            console.log(canSee);
            if (window.location.hash) {
                const scrolledY = window.pageYOffset;
                window.scroll(0, scrolledY - 140);
            }
        });   
     